  .dot-selector-grid{
    height: 35vh;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    overflow: scroll;
  }

  .dot-selected-grid {
    display: grid;
    height: 120px;
    /* Create a layout that flows items horizontally */
    grid-auto-flow: column;
    /* Define a minimum width for each column, allowing as many as needed */
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    /* Enable horizontal scrolling */
    overflow-x: scroll;
    overflow-y: hidden; /* Prevent vertical scrolling */
  }
  
  .dot-selector-item {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 2px solid transparent;
  }

  .dot-selector-button, .dot-selector-button button {
    width: 100%;
  }
.burn-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 10px;
  }
  
  .burn-token-item {
    width: 240px;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid transparent;
  }

  .burn-token-button {
    text-align: center;
    width: 100%;
  }
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Geometric";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a, a:visited {
  color: "black";
  text-decoration: none; 
}

a:hover {
  text-decoration: underline;
}

.mobile-link:hover {
  text-decoration: none;
}